import React from "react"
import { css } from "@emotion/react"

import SEO from "components/SEO"
import Layout from "../components/layout"
import { H2, P } from "../components/headings"
import Container from "../components/container"
import Button from "../components/button"

import Image404 from "../../static/404.svg"

const containerStyles = css`
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	padding: 10rem 1rem !important;

	button {
		max-width: 20rem;
	}
`
const footerConfig = {
	hideDemo: true,
	hideNewsletter: false,
}

const NotFoundPage = () => (
	<Layout footerConfig={footerConfig}>
		<SEO title="404: Not found" />
		<Container css={containerStyles}>
			<H2>Oops!</H2>
			<P>The page you were looking for doesn’t exist</P>
			<img src={Image404} alt="Construction site with 404 in background" />
			<Button label="Go Home" variant="primary" type="button" link={{ url: "/" }} />
		</Container>
	</Layout>
)

export default NotFoundPage
